// Require the polyfill before requiring any other modules.
import "typeface-ubuntu"
import React from 'react'
import Helmet from 'react-helmet'
import Header from "../components/header.js"
import Footer from "../components/footer.js"
import gsap from "gsap"
import { ScrollToPlugin } from "gsap/ScrollToPlugin.js"
gsap.registerPlugin(ScrollToPlugin)
require('intersection-observer');

let isSafari

// Not ideal, but last resort to detect Safari - pathLength attribute not yet supported, so breaks SVG path animation
if (typeof window !== "undefined") {
  isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      navIsOpen: false,
      activeModal: null,
      pageIsReady: false
    }

    this.scrollPage = this.scrollPage.bind(this)
    this.hideNav = this.hideNav.bind(this)
    this.toggleNav = this.toggleNav.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.page = React.createRef()
  }

  componentDidMount() {
    this.setState({
      pageIsReady: true
    })

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        const target = entry.target

        if (entry.intersectionRatio > 0) {
          target.classList.add('is-visible')
          observer.unobserve(target)
        }
      })
    })

    const page = this.page.current
    const els = Array.from(page.querySelectorAll('.animate'))

    els.forEach((el, i) => {
      observer.observe(el)
    })

    if (typeof window !== "undefined") {
      // if tab key is used, add class to show outline styles
      function handleFirstTab(e) {
        if (e.keyCode === 9) {
          document.body.classList.add('user-is-tabbing');
          window.removeEventListener('keydown', handleFirstTab);
        }
      }

      window.addEventListener('keydown', handleFirstTab);
    }
  }

  scrollPage(e) {
    this.hideNav()
    e.persist()
    e.preventDefault()
    const page = this.page.current
    const selector = `#${e.currentTarget.href.split('#')[1]}`
    const match = page.querySelector(selector)

    if (selector === '#page') {
      gsap.to(window, {duration: 1, scrollTo: {y: 0, autoKill: false}, ease: "Power3.easeOut"});
    } else if (match) {
      gsap.to(window, {duration: 1, scrollTo: {y: match, autoKill: false}, ease: "Power3.easeOut"});
    }
  }

  hideNav() {
    this.setState({
      navIsOpen: false
    })
  }

  toggleNav() {
    this.setState({
      navIsOpen: !this.state.navIsOpen
    })
  }

  toggleModal(e) {
    e.persist()
    const selector = e.currentTarget.dataset.modal
    const { activeModal } = this.state
    if (activeModal) {
      this.setState({
        activeModal: null
      })
    } else {
      this.setState({
        activeModal: selector
      })
    }
  }

  render () {
    const {
      navIsOpen,
      activeModal,
      pageIsReady
    } = this.state

    let bodyClasses = ''
    if (navIsOpen) {
      bodyClasses = bodyClasses + 'nav-is-open'
    }
    if (activeModal) {
      bodyClasses = bodyClasses + 'modal-is-open'
    }

    const htmlClasses = bodyClasses

    const children = React.Children.map(this.props.children, child => {
      return React.cloneElement(child, {
        activeModal,
        toggleModal: this.toggleModal,
        scrollPage: this.scrollPage
      })
    })

    return (
      <div ref={this.page} className={`${pageIsReady ? 'page-is-ready' : ''} ${isSafari ? 'is-safari' : ''} page`}>
        <Helmet htmlAttributes={{ class: htmlClasses }} bodyAttributes={{ class: bodyClasses }}>
          <title>Futurebuilders England Fund</title>
          <meta name="description" content="Futurebuilders was the first social investment fund in the UK."/>
          <link rel="canonical" href="https://futurebuilders.socialeconomydatalab.org"/>
          <meta property="og:title" content="Futurebuilders England Fund"/>
          <meta property="og:description" content="Futurebuilders England was a ground-breaking, Government-backed £142m social investment fund that provided loan financing to social sector organisations..."/>
          <meta property="og:image" content="https://futurebuilders.socialeconomydatalab.org/thumbnail.jpg"/>
          <meta property="og:url" content="https://futurebuilders.socialeconomydatalab.org"/>
          <meta name="twitter:title" content="Futurebuilders England Fund"/>
          <meta name="twitter:description" content="Futurebuilders England was a ground-breaking, Government-backed £142m social investment fund that provided loan financing to social sector organisations..."/>
          <meta name="twitter:image" content=" https://futurebuilders.socialeconomydatalab.org/thumbnail.jpg"/>
        </Helmet>
        <Header toggleNav={this.toggleNav} hideNav={this.hideNav} scrollPage={this.scrollPage}/>
        <main className="content">
          <div className="container">
            {children}
          </div>
        </main>
        <Footer/>
      </div>
    )
  }
}

export default Layout;
