import React from "react"

export default function Nav(props) {
  const { scrollPage } = props

  return (
    <nav className="nav">
      <div className="nav__links">
        <a onClick={scrollPage} className="nav__link" href="#purpose">Purpose</a>
        <a onClick={scrollPage} className="nav__link" href="#patience">Patience</a>
        <a onClick={scrollPage} className="nav__link" href="#results">Results</a>
        <a onClick={scrollPage} className="nav__link" href="#future">The Future</a>
      </div>
    </nav>
  )
}
