import React from "react"
import { Link } from "gatsby"
import SibLogo from "../components/svgs/sib-logo.js"
import SedlLogo from "../images/sedl-footer-logo.png"
import DcmsLogo from "../images/dcms-footer-logo.png"

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer__grid">
        <div className="footer__left">
          <div className="footer__logos">
            <Link to="/" className="footer__logo">
              <span className="visually-hidden">Home</span>
              <SibLogo/>
            </Link>
            <div className="footer__partners">
              <a className="footer__logo footer__logo--partner-1" target="_blank" href="https://socialeconomydatalab.org/" rel="noreferrer">
                <span className="visually-hidden">SEDL</span>
                <img src={SedlLogo} alt="SEDL logo" />
              </a>
              <a className="footer__logo footer__logo--partner-2" target="_blank" href="https://www.gov.uk/government/organisations/department-for-digital-culture-media-sport" rel="noreferrer">
                <span className="visually-hidden">DCMS</span>
                <img src={DcmsLogo} alt="SEDL logo" />
              </a>
            </div>
          </div>
          <div className="footer__text">
            <p>The Social Investment Business is the trading name for the Social Investment Business Foundation, Registered Company No. 05777484 (England), Registered Charity No. 1117185 (England & Wales), The Social Investment Business Limited, Registered in England No. 06490609, VAT No. 927456693, Futurebuilders-England Limited, Registered in England No. 05066676 and Forward Enterprise FM Ltd, Registered in England No.11238102. Registered Office: CAN Mezzanine, Borough, 7-14 Great Dover Street, London, SE1 4YR.</p>
          </div>
        </div>
        <div className="footer__right">
          <div className="footer__links">
            <a className="footer__link" target="_blank" href="mailto:enquiries@sibgroup.org.uk" rel="noreferrer">Contact</a>
            <a className="footer__link" target="_blank" href="https://www.sibgroup.org.uk/data-privacy-policy" rel="noreferrer">Privacy Policy</a>
          </div>
          <div className="footer__social">
            <span>Social</span>
            <div>
              <a className="footer__link" target="_blank" href="https://www.linkedin.com/company/the-social-investment-business" rel="noreferrer">LinkedIn</a>
              <a className="footer__link" target="_blank" href="http://www.twitter.com/thesocialinvest" rel="noreferrer">Twitter</a>
              <a className="footer__link" target="_blank" href="https://www.facebook.com/socialinvestmentbusiness" rel="noreferrer">Facebook</a>
              <a className="footer__link" target="_blank" href="https://www.youtube.com/user/SocialInvestment" rel="noreferrer">Youtube</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
