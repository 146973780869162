import React from 'react'

export default function NavBtn(props) {
  const { toggleNav } = props

  return (
    <button className="nav-btn" onClick={toggleNav}>
      <span className="visually-hidden">Menu</span>
      <span className="nav-btn__line nav-btn__line--1"></span>
      <span className="nav-btn__line nav-btn__line--2"></span>
      <span className="nav-btn__line nav-btn__line--3"></span>
    </button>
  )
}
