import React from "react"
import { Link } from "gatsby"
import Nav from "../components/nav.js"
import NavBtn from "../components/nav-btn.js"
import SibLogo from "../components/svgs/sib-logo.js"
import SedlLogo from "../images/sedl-header-logo.png"
import DcmsLogo from "../images/dcms-header-logo.png"

export default function Header(props) {
  return (
    <header className="header">
      <div className="header__container">
        <div className="header__logos">
          <Link to="/" className="header__logo">
            <span className="visually-hidden">Home</span>
            <SibLogo/>
          </Link>
          <a className="header__logo header__logo--partner-1" target="_blank" href="https://socialeconomydatalab.org/" rel="noreferrer">
            <span className="visually-hidden">SEDL</span>
            <img src={SedlLogo} alt="SEDL logo" />
          </a>
          <a className="header__logo header__logo--partner-2" target="_blank" href="https://www.gov.uk/government/organisations/department-for-digital-culture-media-sport" rel="noreferrer">
            <span className="visually-hidden">DCMS</span>
            <img src={DcmsLogo} alt="SEDL logo" />
          </a>
        </div>

        <Nav {...props} />
        <NavBtn {...props} />
      </div>
    </header>
  )
}
